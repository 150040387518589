import BaseModal from "@components/atoms/base-modal";
import Button from "@components/atoms/button";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import classNames from "classnames";
import { sdk, sdkv1 } from "@composer/models/api";
import Cookies from "cookie-universal";
import { useAtomValue } from "jotai";
import { UserAtom } from "@models/state";
import { toast } from "react-toastify";
import { useRouter } from "next/router";
import { useEffect } from "react";
import useAmplitude from "@models/interactions/use-amplitude";

interface Props {
  open?: boolean;
  collection?: any;
  onClose?: () => void;
  onDelete?: (id: number) => void;
}

const cookies = Cookies();

export function useDeleteCollectionModal(props: Props) {
  const { operations: amplitude } = useAmplitude();
  const router = useRouter();
  const user = useAtomValue(UserAtom);

  const handleDelete = async () => {
    try {
      const token = cookies.get("token");
      await sdkv1.http.http.delete(
        `/users/${user?.id}/lists/${props.collection.id}`,
        {
          headers: {
            Authorization: `Bearer ${token || ""}`,
            "content-type": "text/json",
          },
        },
      );

      amplitude.send("Deleted Entity", {
        board_id: props.collection.id,
        board_title: props.collection.list_name,
        title: user?.displayname,
        event_name: "Deleted Collection",
        entity_type: "collection",
        deleted_at: Date.now(),
      });

      toast(`${translate("base.saved-changes") }`,
        { icon: <Icon.NotificationCheck /> },
      );

      if (typeof props.onDelete ==="function") {
        props.onDelete?.(props.collection.id);
      } else {
        router.replace("/library");
      }
    } catch (e) {
      props.onClose?.();
    }
  };

  useEffect(() => {
    if (props.open) {
      amplitude.send("Delete Entity", {
        board_id: props.collection.id,
        board_title: props.collection.list_name,
        title: user?.displayname,
        event_name: "opened",
        entity_type: "collection",
        opened_at: Date.now(),
        modal: true,
      });
    } 
  }, [props.open]);

  return {
    model: {},
    operations: {
      handleDelete,
    },
  };
}

export default function DeleteCollectionModal(props: Props) {
  const { operations, model } = useDeleteCollectionModal(props);

  return (
    <BaseModal open={props.open}>
      <div
        className={classNames(
          "flex h-[234px] w-[375px] flex-col items-center gap-4 bg-memod-base p-4",
        )}
      >
        <div className="flex w-full flex-row justify-end">
          <button onClick={props.onClose}>
            <Icon.Close className="text-white" />
          </button>
        </div>
        <div className="flex w-full flex-col items-center gap-5 p-0">
          <header className="h-[22px] w-[280px]">
            <h3 className="text-center text-xl text-base-white">
              {translate("base.delete-collection")}
            </h3>
          </header>
          <span className="flex w-full flex-col items-center gap-[30px] px-[10px] py-0 pb-0">
            <div className="h-12 w-[280px]">
              <h3 className=" text-center text-[#EBEBF5]">
                {translate("base.delete-collection-message")}
              </h3>
            </div>
            <div className="flex flex-row items-start gap-5 p-0">
              <Button.Pill
                size="default"
                onClick={props.onClose}
                className="!h-12 !w-[100px] !border !border-base-white !bg-transparent !text-base-white"
              >
                {translate("base.cancel")}
              </Button.Pill>
              <Button.Pill
                variant="secondary"
                onClick={operations.handleDelete}
                size="default"
                className="!h-[48px] !w-[100px]"
              >
                {translate("base.delete")}
              </Button.Pill>
            </div>
          </span>
        </div>
      </div>
    </BaseModal>
  );
}
