import { MemoInterface, TopicInterface } from "@carglassgithub/memod-sdk";
import BaseModal from "@components/atoms/base-modal";
import Icon from "@components/atoms/icon";
import { translate } from "@locales";
import classNames from "classnames";
import Link from "next/link";
import {
  FacebookShareButton,
  LinkedinShareButton,
  RedditShareButton,
  WhatsappShareButton,
  EmailShareButton,
} from "react-share";
import useAmplitude from "@models/interactions/use-amplitude";
import { useEffect } from "react";

interface Props {
  open: boolean;
  collection: any;
  onClose?: () => void;
}

interface Network {
  label: string;
  name: string;
}

export function useCollectionShareModal(props: Props) {
  const { collection } = props;
  const { operations } = useAmplitude();

  const copyLink = () => {
    if (navigator) {
      navigator.clipboard.writeText(window.location.href);
    }
  };

  const getUrl = () => {
    if (globalThis) {
      return globalThis?.location?.href;
    }
  };

  const trackShare = (network: Network) => {
    operations.send("Shared Entity", {
      entity_id: collection.id,
      share_at: Date.now(),
      parent_id: collection.user.id,
      social_site: network.label,
      entiy_name: network.name,
      entity_type: "collection",
    });
  };

  const shareTwitter = (props: {
    url: string;
    title: string;
    related: string[];
    hashtags: string[];
  }) => {
    const url = new URL("https://twitter.com/share");

    url.searchParams.append("url", props.url);
    url.searchParams.append("text", props.title);
    url.searchParams.append("related", props.related.join(", "));
    url.searchParams.append("hashtags", props.hashtags.join(", "));

    window.open(url.toString(), "_blank");
  };

  useEffect(() => {
    if (props.open) {
      operations.send("Share Entity", {
        entity_id: collection.id,
        share_at: Date.now(),
        parent_id: collection.user.id,
        event_name: "opened",
        entity_type: "collection",
        opened_at: Date.now(),
        modal: true,
      });
    }
  }, [props.open]);

  return {
    model: {
      url: getUrl(),
    },
    operations: {
      copyLink,
      trackShare,
      shareTwitter,
    },
  };
}

export default function CollectionShareModal(props: Props) {
  const { operations, model } = useCollectionShareModal(props);

  return (
    <BaseModal open={props.open}>
      <div
        className={classNames(
          "flex h-[510px] w-80 sm:w-96 flex-col bg-memod-base py-4 !pb-8"
        )}
      >
        <div className="flex w-full flex-row items-center justify-center border-b border-base-grey px-6 py-2">
          <div className="flex w-11/12 justify-center">
            <span className="text-2xl text-base-white">
              {translate("base.share-to")}...
            </span>
          </div>

          <div className="flex w-1/12 flex-row justify-end">
            <button className="justify-self-end" onClick={props.onClose}>
              <Icon.Close className="text-white" />
            </button>
          </div>
        </div>

        <div className="flex flex-col py-4">
          <div
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
            onClick={() => {
              operations.shareTwitter({
                url: model.url!,
                title: props.collection.title,
                related: [props.collection.description],
                hashtags: [],
              });

              operations.trackShare({ label: "twitter", name: "Twitter" });
            }}
          >
            <span>
              <Icon.Twitter />
            </span>
            <span>{translate("base.share-to")} Twitter</span>
          </div>

          <LinkedinShareButton
            url={model.url!}
            source={model.url!}
            title={props.collection.title}
            summary={props.collection.description}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
            onClick={() =>
              operations.trackShare({ label: "linkedin", name: "LinkedIn" })
            }
          >
            <span>
              <Icon.Linkedin />
            </span>
            <span>{translate("base.share-to")} LinkedIn</span>
          </LinkedinShareButton>

          <FacebookShareButton
            url={model.url!}
            title={props.collection.title}
            quote={props.collection.description}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
            onClick={() =>
              operations.trackShare({ label: "facebook", name: "Facebook" })
            }
          >
            <span className="ml-1 mr-1">
              <Icon.Facebook />
            </span>
            <span>{translate("base.share-to")} Facebook</span>
          </FacebookShareButton>

          <WhatsappShareButton
            url={model.url!}
            title={props.collection.title}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
            onClick={() =>
              operations.trackShare({ label: "whatsapp", name: "WhatsApp" })
            }
          >
            <span>
              <Icon.Whatsapp />
            </span>
            <span>{translate("base.share-to")} Whatsapp</span>
          </WhatsappShareButton>

          <RedditShareButton
            url={model.url!}
            title={props.collection.title}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
            onClick={() =>
              operations.trackShare({ label: "reddit", name: "Reddit" })
            }
          >
            <span>
              <Icon.Reddit />
            </span>
            <span>{translate("base.share-to")} Reddit</span>
          </RedditShareButton>

          <EmailShareButton
            url={model.url!}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
            onClick={() =>
              operations.trackShare({ label: "email", name: "Email" })
            }
          >
            <span>
              <Icon.Email />
            </span>
            <span>{translate("base.share-to")} Email</span>
          </EmailShareButton>

          <Link
            href="#"
            onClick={operations.copyLink}
            className="!my-1 !flex !flex-row !items-center !space-x-3 !p-3 !text-base-white hover:!bg-memod-button"
          >
            <span>
              <Icon.Link />
            </span>
            <span>{translate("base.copy-link")}</span>
          </Link>
        </div>
      </div>
    </BaseModal>
  );
}
