import classNames from "classnames";
import Icon from "@components/atoms/icon";

type Props = {
  active: boolean;
  title?: string;
  onFollow?: () => void;
  onUnfollow?: () => void;
}

export default function FollowIcon(props: Props) {
  if (props.active) {
    return (
      <span
        title={props.title}
        className="cursor-pointer" onClick={props.onUnfollow}
      >
        <Icon.VerifyUser size={22} />
      </span>
    )
  }

  return (
    <span
      title={props.title}
      className={classNames(
        "flex items-center justify-center text-base-white",
        "border border-base-white rounded-full w-[22px] h-[22px] cursor-pointer"
      )}
      onClick={props.onFollow}
    >
      <Icon.Add size={10} />
    </span>
  );
}