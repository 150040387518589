import { useMemo, useReducer, useState } from "react";
import Link from "next/link";
import { useRouter } from "next/router";
import classNames from "classnames";
import Cookies from "cookie-universal";
import { toast } from "react-toastify";
import {
  BoardsInterface as CollectionInterface,
  WriterInterface,
} from "@carglassgithub/memod-sdk";
import { translate } from "@locales";
import { sdkv1 } from "@models/api";
import useAmplitude from "@models/interactions/use-amplitude";
import { useCollectionCover } from "@models/interactions/use-memo-cover";
import { interpolatedTextString } from "@models/interactions/interpolate-text";
import DownDrop, { OptionInterface } from "@components/atoms/drop-down";
import Icon from "@components/atoms/icon";
import CollectionShareModal from "@components/molecules/collection-share-modal";
import DeleteCollectionModal from "@components/molecules/delete-collection-modal";
import FollowIcon from "@components/molecules/follow-icon";
import UserInformation from "@components/molecules/user-information";
import { resizeImage } from "@models/interactions/utils";

type Props = {
  variant: Variants;
  collection: CollectionInterface;
  writer?: WriterInterface;
  className?: CSSClasses;
  isUserLoggedIn?: boolean;
  isAuthor?: boolean;
  isPrivate?: boolean;
  showFollowIcon?: boolean;
  handleLogin?: () => void;
  onDelete?: (id: number) => void;
};

type HeaderProps = {
  variant: Variants;
  totalMemos: number;
  options: OptionInterface[];
  className?: string;
};

type Variants = "simple" | "detailed";

type CSSClasses = {
  container?: string;
  header?: string;
  content?: string;
  title?: string;
  image?: string;
  footer?: string;
};

const cookies = Cookies();

const variantClasses = {
  simple: {
    container: "gap-2 px-2 pb-2",
    header: "",
    content: "",
    title: "",
    image: "",
    footer: "",
  },
  detailed: {
    container: "px-2 md:px-3 md:pt-px pb-3",
    header:
      "md:h-7 [&>div]:md:text-base group [&>div>:nth-child(3)]:md:w-[92px]",
    title: "md:text-xl md:leading-8",
    content: "items-center md:gap-5",
    image: "md:w-[132px] md:h-[89px]",
    footer: "md:text-base",
  },
};

const defaultCollectionImage = {
  url: "/img/defaults/memo-placeholder.jpg",
  alt: "",
};

function Header(props: HeaderProps) {
  return (
    <div
      className={classNames(
        "flex h-6 items-center justify-between",
        props.className
      )}
    >
      <div className="flex items-center self-start rounded bg-composer-active px-1 py-0.5 text-sm leading-4 text-memod-white-base">
        <span className="leading-5">Collection</span>
        <span className="mx-1.5">|</span>
        <span className="inline-block w-[72px]">{props.totalMemos} Memos</span>
      </div>
      {props.variant === "detailed" && (
        <DownDrop
          iconButton={
            <span className="flex h-6 w-6 items-center justify-center">
              <Icon.ThreeDots size={24} fill="#FFF" />
            </span>
          }
          options={props.options}
          optionClassName="items-center"
        />
      )}
    </div>
  );
}

function useCollectionCard(props: Props) {
  const router = useRouter();
  const { operations: amplitude } = useAmplitude();
  const { cover } = useCollectionCover(props.collection.files);
  const [isPublic, setIsPublic] = useState(props.collection.is_public === 1);
  const [isFollowing, setIsFollowing] = useState(
    props.collection.is_followed === 1
  );
  const [isFetching, setIsFetching] = useState({
    following: false,
  });
  const [showShareModal, toggleShowShareModal] = useReducer(
    (state) => !state,
    false
  );
  const [showDeleteModal, toggleShowDeleteModal] = useReducer(
    (state) => !state,
    false
  );

  const className = useMemo(() => {
    if (props.variant) {
      return variantClasses[props.variant as keyof typeof variantClasses];
    }

    return {} as CSSClasses;
  }, []);

  const writer = useMemo(() => {
    return props?.writer ?? props.collection.user;
  }, []);

  const collectionImage = useMemo(() => {
    if (cover) {
      return {
        url: cover?.attributes?.unique_name
          ? resizeImage(cover.attributes.unique_name!, "400")
          : cover.url,
        alt: cover.name,
      };
    }

    if (props.collection.image_url) {
      return {
        url: props.collection.image_url,
        alt: "Collection Image",
      };
    }

    return defaultCollectionImage;
  }, []);

  const toggleFollow = async () => {
    if (isFetching.following) return;

    setIsFetching((current) => ({ ...current, following: true }));

    try {
      if (props.isUserLoggedIn) {
        const token = cookies.get("token");
        const config = token
          ? { headers: { Authorization: `Bearer ${token}` } }
          : {};
        const { data } = await sdkv1.http.http.post(
          `/boards/${props.collection.id}/follow`,
          {},
          config
        );
        setIsFollowing(data.is_following === 1);
        amplitude.send(
          data.is_following ? "Followed Entity" : "Unfollowed Entity",
          {
            entity_type: "collection",
            [data.is_following ? "followed_at" : "unfollowed_at"]: Date.now(),
            entity_name: props.collection.list_name,
            entity_id: props.collection.id,
          }
        );
        toast(
          data.is_following
            ? translate("library.saved")
            : translate("library.removed"),
          { icon: <Icon.NotificationCheck /> }
        );
      } else {
        amplitude.send("Click", {
          name: `follow-entity-collection`,
          path: router.pathname,
          title: `Follow Entity Collection`,
        });
        props.handleLogin?.();
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsFetching((current) => ({ ...current, following: false }));
    }
  };

  const edit = () => {
    router.push(
      `/${writer.displayname}/collections/${props.collection.slug}-${props.collection.id}?id=${props.collection.id}&open=true`
    );
  };

  const copyLink = () => {
    if (navigator) {
      navigator.clipboard.writeText(
        `${window.location.origin}/${writer.displayname}/${props.collection.slug}-${props.collection.id}`
      );
      toast("Link copied", { icon: <Icon.NotificationCheck /> });
    }
  };

  const togglePublished = async () => {
    if (props.collection.is_default === 1) return;

    try {
      const token = cookies.get("token");
      const config = token
        ? { headers: { Authorization: `Bearer ${token}` } }
        : {};
      const { data } = await sdkv1.http.http.put(
        `/boards/${props.collection.id}`,
        {
          list_name: props.collection.list_name,
          is_public: Number(!isPublic),
          description: props.collection.description,
        },
        config
      );

      setIsPublic(data.is_public === 1);

      const message = interpolatedTextString(
        "base.status-collection",
        props.collection.list_name
      );

      toast([message].flat().join(""), {
        icon: <Icon.NotificationCheck />,
      });
    } catch (e) {
      console.log(e);
    }
  };

  const handleDelete = (id: number) => {
    props.onDelete?.(id);
    toggleShowDeleteModal();
  };

  const collectionUserOptions: OptionInterface[] = [
    {
      text: "Share",
      icon: <Icon.Share size={20} />,
      onClick: () => toggleShowShareModal(),
    },
    {
      text: "Copy link",
      icon: <Icon.Link size={24} />,
      onClick: () => copyLink(),
    },
  ];

  const collectionAuthorOptions: OptionInterface[] = [
    {
      text: "Edit Details",
      icon: <Icon.Edit size={20} />,
      onClick: () => edit(),
    },
    // @ts-ignore
    ...((isPublic && [
      {
        text: "Make Private",
        icon: <Icon.Lock size={20} />,
        onClick: () => togglePublished(),
      },
    ]) ||
      []),
    ...((!isPublic && [
      {
        text: "Make Public",
        icon: <Icon.Lock size={20} stroke="currentColor" />,
        title:
          props.collection.is_default === 1
            ? "Your personal collection cannot be made public."
            : "",
        className: classNames({
          "text-memod-label cursor-help": props.collection.is_default === 1,
        }),
        onClick: () => togglePublished(),
      },
    ]) ||
      []),
    {
      text: "Delete",
      icon: <Icon.Trash size={24} />,
      onClick: () => toggleShowDeleteModal(),
    },
  ];

  return {
    models: {
      writer,
      className,
      collectionImage,
      collectionUserOptions,
      collectionAuthorOptions,
      isFollowing,
      showShareModal,
      showDeleteModal,
    },
    operations: {
      toggleFollow,
      toggleShowShareModal,
      toggleShowDeleteModal,
      handleDelete,
    },
  };
}

export default function CollectionCard(props: Props) {
  const { models, operations } = useCollectionCard(props);
  const { collection } = props;

  return (
    <div
      className={classNames(
        "flex flex-col rounded-lg border border-composer-text/[0.18] hover:bg-[#232326]",
        models.className.container,
        props.className?.container
      )}
    >
      {models.showShareModal && (
        <CollectionShareModal
          open
          collection={props.collection}
          onClose={operations.toggleShowShareModal}
        />
      )}
      {models.showDeleteModal && (
        <DeleteCollectionModal
          open
          collection={props.collection}
          onClose={operations.toggleShowDeleteModal}
          onDelete={operations.handleDelete}
        />
      )}
      {props.variant === "simple" && (
        <Header
          variant={props.variant}
          totalMemos={props.collection.total_memos}
          className={models.className.header}
          options={
            props.isAuthor
              ? models.collectionAuthorOptions
              : models.collectionUserOptions
          }
        />
      )}
      <div className={classNames("flex gap-3", models.className.content)}>
        <Link
          href={`/${models.writer.displayname}/collections/${collection.slug}-${collection.id}`}
          className="relative flex shrink-0 flex-col items-center pt-[18px]"
        >
          <img
            src={models.collectionImage.url}
            alt={models.collectionImage.alt}
            className={classNames(
              "z-10 h-[68px] w-[100px] rounded border border-memod-label bg-composer-bg object-cover",
              models.className.image,
              props.className?.image
            )}
            loading="lazy"
          />
          <div className="absolute top-0 h-1/2 w-[75%] rounded border border-memod-label bg-composer-bg"></div>
          <div className="absolute top-1.5 h-1/2 w-[85%] rounded border border-memod-label bg-composer-bg"></div>
          <div className="absolute top-3 h-1/2 w-[95%] rounded border border-memod-label bg-composer-bg"></div>
        </Link>
        <div className="flex grow flex-col gap-1">
          {props.variant === "detailed" && (
            <Header
              variant={props.variant}
              totalMemos={props.collection.total_memos}
              className={models.className.header}
              options={
                props.isAuthor
                  ? models.collectionAuthorOptions
                  : models.collectionUserOptions
              }
            />
          )}
          <Link
            href={`/${models.writer.displayname}/collections/${collection.slug}-${collection.id}`}
          >
            <h3
              className={classNames(
                "break-word line-clamp-2 h-fit text-base-white",
                models.className.title
              )}
            >
              {collection.list_name}
            </h3>
            {props.variant === "detailed" && (
              <p className="break-word line-clamp-3 min-h-[60px] text-sm text-memod-white-base md:line-clamp-2 md:min-h-[48px] md:text-base">
                {props.collection.description}
              </p>
            )}
          </Link>
          <div className="flex items-center justify-between">
            {!props.isPrivate ? (
              <UserInformation
                writer={models.writer}
                size={"default"}
                avatarSize={20}
                className="!block"
                linkClassName="gap-1"
                textClassName={classNames(
                  "text-composer-text/60",
                  models.className.footer
                )}
              />
            ) : (
              <span />
            )}
            {!props.isAuthor &&
            (props.variant === "detailed" || props.showFollowIcon) ? (
              <FollowIcon
                title={models.isFollowing ? translate("library.remove") : ""}
                active={models.isFollowing}
                onFollow={operations.toggleFollow}
                onUnfollow={operations.toggleFollow}
              />
            ) : null}
            {props.isAuthor && (
              <>
                {props.isPrivate ? (
                  <Icon.Lock size={20} />
                ) : (
                  <span
                    className="cursor-pointer text-base-white"
                    onClick={operations.toggleShowShareModal}
                  >
                    <Icon.Share size={20} strokeWidth={1} />
                  </span>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
